const appConfig = {
    apiPrefix: 'https://api.giuteamconnect.com/apis/admin',
    // apiPrefix: 'http://localhost:8000/apis/admin',

    authenticatedEntryPath: '/app/crm/organizer/2',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
